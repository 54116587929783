import React from 'react'

const Section = ({ children, className, grey, red, halfdark }) => (
  <section
    className={`${className ? `${className} ` : ''}${grey ? 'grey' : ''}${
      red ? ' red' : ''
    }${halfdark ? ' halfdark' : ''}`}
  >
    {halfdark && <div className="additional" />}
    {children}
  </section>
)

export default Section
