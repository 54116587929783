import React, { Fragment } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Item = ({
  type,
  slug,
  title,
  images,
  date,
  superficie,
  prix,
  commercial,
  ...props
}) => {
  const Content = () => {
    return (
      <Fragment>
        {images && <Img fluid={images[0].fluid} />}
        <h5>{title}</h5>
        {commercial ? (
          <div>
            {date && (
              <p>
                <b>Disponibilité:</b> {date}
              </p>
            )}
            {superficie && (
              <p>
                <b>Superficie:</b> {superficie}
              </p>
            )}
          </div>
        ) : (
          <div>
            {prix && prix !== '_' && (
              <p>
                <b>Prix:</b> {prix}
              </p>
            )}
            {/*date && (
							<p>
								<b>Disponibilité:</b> {date}
							</p>
						)*/}
          </div>
        )}
        <button>VOIR LA FICHE COMPLÈTE</button>
      </Fragment>
    )
  }

  if (slug === '2430-rue-gabriel-lajeunesse' || slug == 'le-nordet-phase-2') {
    return (
      <a href={`https://www.lenordet.com/`} target="_blank">
        <Content />
      </a>
    )
  }

  if (slug == 'lastral') {
    return (
      <a href={`https://www.lastral.ca/`} target="_blank">
        <Content />
      </a>
    )
  }

  if (slug == 'le-belair') {
    return (
      <a href={`https://www.lebelair.ca/`} target="_blank">
        <Content />
      </a>
    )
  }

  return (
    <Link to={`/location/${slug}`}>
      <Content />
    </Link>
  )
}

export default Item
