import React from 'react'

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="214px"
    height="fit-content"
    viewBox="0 0 806 249"
    className="logo"
  >
    <title>Fichier 1</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient
        x1="-3.32048621%"
        y1="48.6305111%"
        x2="128.085779%"
        y2="55.6457277%"
        id="linearGradient-1"
      >
        <stop stop-color="#A32621" offset="0%"></stop>
        <stop stop-color="#CF342F" offset="34%"></stop>
        <stop stop-color="#DF5A43" offset="79%"></stop>
        <stop stop-color="#E16B37" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-40.6426269%"
        y1="43.6520627%"
        x2="93.2917002%"
        y2="50.8314679%"
        id="linearGradient-2"
      >
        <stop stop-color="#A32621" offset="0%"></stop>
        <stop stop-color="#CF342F" offset="34%"></stop>
        <stop stop-color="#DF5A43" offset="79%"></stop>
        <stop stop-color="#E16B37" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-3">
        <stop stop-color="#A32621" offset="0%"></stop>
        <stop stop-color="#CF342F" offset="34%"></stop>
        <stop stop-color="#DF5A43" offset="79%"></stop>
        <stop stop-color="#E16B37" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Fichier-1" fill-rule="nonzero">
        <polygon
          id="Shape"
          fill="url(#linearGradient-1)"
          points="156.79 117.55 156.79 146.92 92.35 82.47 92.07 82.19 76.35 82.19 76.35 168.83 86.89 168.83 86.89 189.59 55.6 189.59 55.6 61.44 100.66 61.44"
        ></polygon>
        <polygon
          id="Shape"
          fill="url(#linearGradient-2)"
          points="192.44 60.71 192.44 185.79 148.4 185.79 93.16 130.56 93.16 101.21 157 165.04 171.69 165.04 171.69 81.47 158.7 81.47 158.7 60.71"
        ></polygon>
        <path
          d="M124.09,248.18 C55.5569854,248.18 8.3928737e-15,192.623015 0,124.09 C-8.3928737e-15,55.5569854 55.5569854,1.25893105e-14 124.09,0 C192.623015,-1.25893105e-14 248.18,55.5569854 248.18,124.09 C248.102847,192.591031 192.591031,248.102847 124.09,248.18 Z M124.09,10.48 C78.1340898,10.4800002 36.7034823,38.1637066 19.1178511,80.6218184 C1.53222001,123.07993 11.2548979,171.950702 43.7520637,204.445008 C76.2492295,236.939314 125.120857,246.65769 167.577421,229.068322 C210.033985,211.478954 237.714045,170.04591 237.71,124.09 C237.64385,61.3684467 186.811559,10.5406296 124.09,10.48 Z"
          id="Shape"
          fill="url(#linearGradient-3)"
        ></path>
        <path
          d="M318.63,166.06 C316.258886,163.869781 313.157745,162.640019 309.93,162.61 C302.37,162.61 298.25,168.28 298.25,174.75 C298.25,180.75 302.03,186.3 310.02,186.3 C312.962915,186.307001 315.807339,185.240343 318.02,183.3 L318.02,176.3 L308.86,176.3 L308.86,173.06 L321.34,173.06 L321.34,184.7 C318.456314,187.950892 314.294872,189.777689 309.95,189.7 C299.5,189.7 294.62,182.6 294.62,174.7 C294.62,165.92 300.34,159.16 309.91,159.16 C313.950495,159.149287 317.831843,160.73417 320.71,163.57 L318.63,166.06 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M351.81,185.88 C349.71,188.36 346.09,189.58 342.86,189.58 C336.43,189.58 331.94,185.34 331.94,178.74 C331.832098,175.858095 332.930338,173.06164 334.970529,171.023347 C337.010721,168.985053 339.808197,167.889416 342.69,168 C349.03,168 353.87,171.9 353.07,180.18 L335.42,180.18 C335.8,184 338.95,186.4 342.94,186.4 C345.3,186.4 348.15,185.47 349.54,183.79 L351.85,185.89 L351.81,185.88 Z M349.81,177.31 C349.64,173.31 346.87,171.1 342.81,171.1 C339.127257,170.929336 335.929903,173.613374 335.46,177.27 L349.83,177.27 L349.81,177.31 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M377.47,173.07 C375.86929,171.64889 373.765839,170.92853 371.63,171.07 C368.77,171.07 366.46,172.07 366.5,174.07 C366.54,176.07 369.07,176.84 371.67,177.14 C375.45,177.6 380.49,178.35 380.41,183.48 C380.32,187.64 376.41,189.7 371.71,189.7 C368.23,189.7 364.78,188.7 362.43,185.79 L364.43,183.39 C366.305902,185.493207 369.012722,186.663723 371.83,186.59 C374.1,186.59 376.99,185.87 377.08,183.35 C377.08,181.08 374.68,180.41 371.49,180.03 C367.49,179.53 363.22,178.48 363.22,174.03 C363.22,169.58 367.63,167.9 371.62,167.94 C374.512096,167.788704 377.347182,168.784038 379.51,170.71 L377.51,172.98 L377.47,173.07 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M397.25,168.49 L403.68,168.49 L403.68,171.43 L397.21,171.43 L397.21,182.43 C397.21,184.86 398,186.43 400.48,186.43 C401.491316,186.396572 402.482276,186.136872 403.38,185.67 L404.38,188.53 C403.127042,189.127711 401.766827,189.467765 400.38,189.53 C395.92,189.61 393.82,187 393.82,182.43 L393.82,171.43 L389.67,171.43 L389.67,168.49 L393.82,168.49 L393.82,162.78 L397.27,162.4 L397.25,168.49 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M418.42,162.15 C418.42,165.15 413.88,165.15 413.88,162.15 C413.88,159.15 418.42,159.12 418.42,162.15 Z M414.42,189.15 L417.87,189.15 L417.87,168.37 L414.42,168.37 L414.42,189.15 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M439.84,168 C446.18,168 450.63,172.45 450.63,178.88 C450.63,185.31 446.1,189.59 439.84,189.59 C434.005971,189.412414 429.368595,184.631732 429.368595,178.795 C429.368595,172.958268 434.005971,168.177586 439.84,168 Z M439.84,171.15 C435.6,171.15 432.49,174.26 432.49,178.88 C432.49,182.939293 435.780707,186.23 439.84,186.23 C443.899293,186.23 447.19,182.939293 447.19,178.88 C447.23,174.25 444,171.14 439.88,171.14 L439.84,171.15 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M461.88,168.41 L465,168.41 L465.17,171.73 C466.788092,169.41849 469.448833,168.061887 472.27,168.11 C477.52,168.11 481.39,171.64 481.39,177.73 L481.39,189.2 L478,189.2 L478,177.78 C478,173.78 475.65,171.31 471.7,171.31 C469.945527,171.324176 468.272791,172.053577 467.068533,173.329562 C465.864275,174.605547 465.232761,176.31764 465.32,178.07 L465.32,189.2 L461.91,189.2 L461.91,168.41 L461.88,168.41 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M514.8,162.15 C514.8,165.15 510.27,165.15 510.27,162.15 C510.349287,160.957399 511.339766,160.030582 512.535,160.030582 C513.730234,160.030582 514.720713,160.957399 514.8,162.15 Z M510.8,189.15 L514.25,189.15 L514.25,168.37 L510.8,168.37 L510.8,189.15 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M526.86,168.49 L530.05,168.49 L530.26,171.49 C531.490679,169.282176 533.865129,167.961315 536.39,168.08 C539.25,168.08 542.19,169.3 543.39,172.62 C544.687621,169.770516 547.570562,167.980017 550.7,168.08 C556.16,168.08 559.43,171.65 559.43,177.32 L559.43,189.2 L556,189.2 L556,177.35 C556,173.95 554,171.35 550.62,171.35 C547.24,171.35 544.95,174.04 544.95,177.44 L544.95,189.2 L541.51,189.2 L541.51,177.44 C541.51,174.04 539.36,171.35 535.92,171.35 C532.48,171.35 530.29,174.04 530.29,177.44 L530.29,189.2 L526.85,189.2 L526.85,168.45 L526.86,168.49 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M571.8,168.49 L575,168.49 L575.21,171.49 C576.440679,169.282176 578.815129,167.961315 581.34,168.08 C584.2,168.08 587.14,169.3 588.34,172.62 C589.637621,169.770516 592.520562,167.980017 595.65,168.08 C601.11,168.08 604.38,171.65 604.38,177.32 L604.38,189.2 L600.94,189.2 L600.94,177.35 C600.94,173.95 598.94,171.35 595.56,171.35 C592.18,171.35 589.89,174.04 589.89,177.44 L589.89,189.2 L586.45,189.2 L586.45,177.44 C586.45,174.04 584.3,171.35 580.86,171.35 C577.42,171.35 575.23,174.04 575.23,177.44 L575.23,189.2 L571.79,189.2 L571.79,168.45 L571.8,168.49 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M626.11,168 C632.45,168 636.9,172.45 636.9,178.88 C636.9,185.31 632.36,189.59 626.11,189.59 C620.275971,189.412414 615.638595,184.631732 615.638595,178.795 C615.638595,172.958268 620.275971,168.177586 626.11,168 Z M626.11,171.15 C621.86,171.15 618.76,174.26 618.76,178.88 C618.76,182.939293 622.050707,186.23 626.11,186.23 C630.169293,186.23 633.46,182.939293 633.46,178.88 C633.5,174.25 630.31,171.14 626.15,171.14 L626.11,171.15 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M651.6,172.23 C653.07,169.42 656.43,168.07 659.37,168.07 C665.29,168.07 669.87,171.85 669.87,178.83 C669.87,185.81 665.29,189.58 659.33,189.58 C656.193332,189.681728 653.242915,188.093923 651.6,185.42 L651.43,189.2 L648.15,189.2 L648.15,159.8 L651.6,159.8 L651.6,172.23 Z M651.77,178.78 C651.669512,180.77747 652.404389,182.726735 653.798543,184.160722 C655.192697,185.594709 657.120492,186.384193 659.12,186.34 C663.12,186.34 666.43,183.61 666.43,178.83 C666.43,174.05 663.15,171.31 659.12,171.31 C657.13683,171.267262 655.223883,172.0446 653.832648,173.458549 C652.441413,174.872499 651.695153,176.797782 651.77,178.78 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M685.24,162.15 C685.24,165.15 680.7,165.15 680.7,162.15 C680.7,159.15 685.24,159.12 685.24,162.15 Z M681.24,189.15 L684.68,189.15 L684.68,168.37 L681.24,168.37 L681.24,189.15 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <polygon
          id="Shape"
          fill="#C6322D"
          points="701 189.2 697.5 189.2 697.5 159.8 701 159.8"
        ></polygon>
        <path
          d="M717.83,162.15 C717.83,165.15 713.3,165.15 713.3,162.15 C713.379287,160.957399 714.369766,160.030582 715.565,160.030582 C716.760234,160.030582 717.750713,160.957399 717.83,162.15 Z M713.83,189.15 L717.27,189.15 L717.27,168.37 L713.8,168.37 L713.83,189.15 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M748.41,185.88 C746.31,188.36 742.7,189.58 739.46,189.58 C733.04,189.58 728.54,185.34 728.54,178.74 C728.43513,175.858951 729.534345,173.064283 731.573853,171.026673 C733.61336,168.989063 736.409049,167.892448 739.29,168 C745.64,168 750.47,171.9 749.67,180.18 L732.11,180.18 C732.49,184 735.56,186.4 739.55,186.4 C741.9,186.4 744.76,185.47 746.14,183.79 L748.45,185.89 L748.41,185.88 Z M746.41,177.31 C746.25,173.31 743.47,171.1 739.41,171.1 C735.743976,170.933013 732.571335,173.625612 732.14,177.27 L746.42,177.27 L746.41,177.31 Z M734.61,159.54 L738.85,159.54 L741.96,164.88 L741.96,165.09 L738.81,165.09 L734.61,159.63 L734.61,159.54 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M764,168.41 L764.25,171.52 C765.68,168.83 768.25,168.07 770.64,168.07 C772.378668,168.038506 774.078233,168.587434 775.47,169.63 L773.91,172.57 C772.026321,171.229238 769.549154,171.060343 767.500924,172.133024 C765.452694,173.205705 764.180722,175.338067 764.21,177.65 L764.21,189.2 L760.77,189.2 L760.77,168.45 L764,168.45 L764,168.41 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M802.93,185.88 C800.83,188.36 797.21,189.58 793.98,189.58 C787.55,189.58 783.06,185.34 783.06,178.74 C782.952098,175.858095 784.050338,173.06164 786.090529,171.023347 C788.130721,168.985053 790.928197,167.889416 793.81,168 C800.15,168 804.98,171.9 804.19,180.18 L786.54,180.18 C786.92,184 790.07,186.4 794.06,186.4 C796.41,186.4 799.27,185.47 800.66,183.79 L802.97,185.89 L802.93,185.88 Z M800.93,177.31 C800.76,173.31 797.99,171.1 793.93,171.1 C790.247257,170.929336 787.049903,173.613374 786.58,177.27 L800.95,177.27 L800.93,177.31 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M348,143.88 L317.15,98 C315.15,94.88 312.35,90.68 311.27,88.52 C311.27,91.64 311.51,102.2 311.51,106.88 L311.51,143.88 L295,143.88 L295,59.88 L311,59.88 L340.76,104.28 C342.76,107.4 345.56,111.6 346.64,113.76 C346.64,110.64 346.4,100.08 346.4,95.4 L346.4,59.88 L363,59.88 L363,143.88 L348,143.88 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M416.63,145.2 C393.71,145.2 380.15,127.44 380.15,101.88 C380.15,76.32 393.71,58.56 416.63,58.56 C439.55,58.56 453.11,76.32 453.11,101.88 C453.11,127.44 439.55,145.2 416.63,145.2 Z M416.63,75.2 C403.07,75.2 397.43,86.84 397.43,101.84 C397.43,116.84 403.07,128.48 416.63,128.48 C430.19,128.48 435.83,116.84 435.83,101.84 C435.83,86.84 430.19,75.24 416.63,75.24 L416.63,75.2 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M532.07,143.88 L532.07,113 C532.07,108.44 532.31,97.76 532.31,94.52 C531.11,97.64 528.59,103.64 526.43,108.44 L509.43,145.76 L492.43,108.44 C490.27,103.64 487.75,97.64 486.55,94.52 C486.55,97.76 486.79,108.44 486.79,113 L486.79,143.84 L470.27,143.84 L470.27,59.84 L486.47,59.84 L503.99,97.84 C506.15,102.52 508.67,108.84 509.75,111.84 C510.83,108.72 513.23,102.6 515.39,97.84 L532.31,59.84 L548.87,59.84 L548.87,143.84 L532.07,143.88 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M626.39,143.88 L619.79,125.16 L589.07,125.16 L582.47,143.88 L564.47,143.88 L596.63,59.88 L613,59.88 L644.92,143.88 L626.39,143.88 Z M608.63,94 C606.95,89.08 605.27,84 604.43,80.92 C603.59,84 601.91,89 600.23,94 L594.83,109.24 L614,109.24 L608.63,94 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <path
          d="M685.55,143.88 L660.47,143.88 L660.47,59.88 L686.75,59.88 C710.75,59.88 728.51,71.16 728.51,101.88 C728.51,131.4 707.15,143.88 685.55,143.88 Z M686.15,76.2 L677.27,76.2 L677.27,127.56 L685.55,127.56 C703.19,127.56 711.23,117.24 711.23,101.88 C711.23,84.72 703.91,76.2 686.15,76.2 Z"
          id="Shape"
          fill="#C6322D"
        ></path>
        <polygon
          id="Shape"
          fill="#C6322D"
          points="745.31 143.88 745.31 59.88 803.63 59.88 803.63 76.32 762.11 76.32 762.11 90.84 786.23 90.84 786.23 107.16 762.11 107.16 762.11 127.44 805.43 127.44 805.43 143.88"
        ></polygon>
      </g>
    </g>
  </svg>
)

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="24"
    viewBox="0 0 14 24"
  >
    <desc>Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <path d="M6.5 20C7.3 20 8 20.7 8 21.5 8 22.3 7.3 23 6.5 23 5.7 23 5 22.3 5 21.5 5 20.7 5.7 20 6.5 20ZM6.5 22.2C6.9 22.2 7.3 21.9 7.3 21.5 7.3 21.1 6.9 20.8 6.5 20.8 6.1 20.8 5.7 21.1 5.7 21.5 5.7 21.9 6.1 22.2 6.5 22.2Z" />
        <path d="M5.3 1.8L6.7 1.8C6.9 1.8 7 1.6 7 1.4 7 1.2 6.9 1 6.7 1L5.3 1C5.1 1 5 1.2 5 1.4 5 1.6 5.1 1.8 5.3 1.8" />
        <path d="M8.3 1.8L8.7 1.8C8.9 1.8 9 1.6 9 1.4 9 1.2 8.9 1 8.7 1L8.3 1C8.1 1 8 1.2 8 1.4 8 1.6 8.1 1.8 8.3 1.8" />
        <path d="M11.8 0.1C12.8 0.1 13.5 0.9 13.5 1.9L13.5 22.2C13.5 23.2 12.8 24 11.8 24L1.8 24C0.8 24 0 23.2 0 22.2L0 1.9C0 0.9 0.8 0.1 1.8 0.1L11.8 0.1ZM0.8 18.4L12.7 18.4 12.7 3.3 0.8 3.3 0.8 18.4ZM1.8 0.9C1.2 0.9 0.8 1.3 0.8 1.9L0.8 2.5 12.7 2.5 12.7 1.9C12.7 1.3 12.3 0.9 11.8 0.9L1.8 0.9ZM11.8 23.2C12.3 23.2 12.7 22.8 12.7 22.2L12.7 19.2 0.8 19.2 0.8 22.2C0.8 22.8 1.2 23.2 1.8 23.2L11.8 23.2Z" />
      </g>
    </g>
  </svg>
)

const MailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="15"
    viewBox="0 0 21 15"
  >
    <desc> Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <path d="M19.8 0C20.1 0 20.3 0.2 20.3 0.5L20.3 14.3C20.3 14.5 20.1 14.7 19.8 14.7L0.5 14.7C0.2 14.7 0 14.5 0 14.3L0 0.5C0 0.2 0.2 0 0.5 0L19.8 0ZM19.4 12.7L19.4 0.9 0.9 0.9 0.9 12.7 6.6 7 7.3 7.7 1.1 13.8 19.2 13.8 13 7.7 13.6 7 19.4 12.7Z" />
        <path d="M18.4 0.9L10.1 9.2 1.9 0.9 0.9 0.9 0.9 1.3 9.8 10.2C10 10.4 10.3 10.4 10.5 10.2L19.4 1.3 19.4 0.9 18.4 0.9Z" />
      </g>
    </g>
  </svg>
)

const PinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="26"
    viewBox="0 0 14 26"
  >
    <desc> Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <path d="M13.2 6.6C13.2 10.1 10.5 13 7.1 13.2L7.1 25.6C7.1 25.8 6.9 26 6.6 26 6.4 26 6.2 25.8 6.2 25.6L6.2 13.2C2.7 13 0 10.1 0 6.6 0 3 3 0 6.6 0 10.3 0 13.2 3 13.2 6.6ZM6.6 12.4C9.8 12.4 12.4 9.8 12.4 6.6 12.4 3.5 9.8 0.9 6.6 0.9 3.5 0.9 0.9 3.5 0.9 6.6 0.9 9.8 3.5 12.4 6.6 12.4Z" />
        <path d="M4.4 3.1C5.4 3.1 6.2 3.9 6.2 4.9 6.2 5.8 5.4 6.6 4.4 6.6 3.4 6.6 2.6 5.8 2.6 4.9 2.6 3.9 3.4 3.1 4.4 3.1ZM4.4 5.7C4.9 5.7 5.3 5.3 5.3 4.9 5.3 4.4 4.9 4 4.4 4 3.9 4 3.5 4.4 3.5 4.9 3.5 5.3 3.9 5.7 4.4 5.7Z" />
      </g>
    </g>
  </svg>
)

const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13"
    viewBox="0 0 14 13"
  >
    <desc> Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <path d="M0.2 12.8L0.2 4.2 3.1 4.2 3.1 12.8 0.2 12.8ZM12.5 5C13.1 5.6 13.4 6.6 13.4 7.9L13.4 12.8 10.5 12.8 10.5 8.2C10.5 7.6 10.4 7.1 10.2 6.8 10 6.4 9.6 6.2 9.1 6.2 8.7 6.2 8.4 6.3 8.2 6.5 7.9 6.7 7.7 7 7.6 7.3 7.5 7.5 7.5 7.7 7.5 8L7.5 12.8 4.6 12.8C4.7 10.5 4.7 8.6 4.7 7.2 4.7 5.7 4.7 4.9 4.6 4.6L4.6 4.2 7.5 4.2 7.5 5.4C7.6 5.2 7.7 5.1 7.9 4.9 8 4.8 8.1 4.6 8.4 4.5 8.6 4.3 8.8 4.2 9.1 4.1 9.4 4 9.7 4 10.1 4 11.1 4 11.9 4.3 12.5 5ZM1.6 0C2.1 0 2.5 0.1 2.8 0.4 3.1 0.7 3.2 1.1 3.2 1.5 3.2 1.9 3.1 2.3 2.8 2.6 2.5 2.8 2.1 3 1.6 3L1.6 3C1.1 3 0.7 2.8 0.4 2.6 0.1 2.3 0 1.9 0 1.5 0 1.1 0.1 0.7 0.4 0.4 0.8 0.1 1.1 0 1.6 0Z" />
      </g>
    </g>
  </svg>
)

const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
  >
    <desc> Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <path d="M6.8 0L5.1 0C3.2 0 2 1.3 2 3.2L2 4.7 0.3 4.7C0.1 4.7 0 4.8 0 5L0 7.1C0 7.3 0.1 7.4 0.3 7.4L2 7.4 2 12.8C2 13 2.1 13.1 2.2 13.1L4.5 13.1C4.6 13.1 4.7 13 4.7 12.8L4.7 7.4 6.7 7.4C6.9 7.4 7 7.3 7 7.1L7 5C7 4.9 7 4.8 6.9 4.8 6.9 4.7 6.8 4.7 6.7 4.7L4.7 4.7 4.7 3.5C4.7 2.8 4.9 2.5 5.7 2.5L6.8 2.5C7 2.5 7.1 2.4 7.1 2.3L7.1 0.3C7.1 0.1 7 0 6.8 0" />
      </g>
    </g>
  </svg>
)

const Chevron = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="14"
    viewBox="0 0 9 14"
  >
    <desc> Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <polygon points="1.3 0 0 1.4 5.5 6.2 0.5 11.9 2 13.2 8.2 6" />
      </g>
    </g>
  </svg>
)

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <desc>Created with Sketch.</desc>
    <g fill="none">
      <g fill="#FFF">
        <path d="M20.6 19.4L27.3 26.1C27.6 26.4 27.6 27 27.3 27.3 27.1 27.5 26.9 27.5 26.7 27.5 26.5 27.5 26.3 27.4 26.1 27.3L19.4 20.6C17.4 22.4 14.7 23.5 11.7 23.5 5.3 23.5 0 18.2 0 11.7 0 5.3 5.3 0 11.7 0 18.2 0 23.5 5.3 23.5 11.7 23.5 14.7 22.4 17.4 20.6 19.4ZM1.6 11.7C1.6 17.3 6.2 21.8 11.7 21.8 17.3 21.8 21.8 17.3 21.8 11.7 21.8 6.2 17.3 1.6 11.7 1.6 6.2 1.6 1.6 6.2 1.6 11.7Z" />
      </g>
    </g>
  </svg>
)

const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="36"
    viewBox="0 0 29 36"
  >
    <desc> Created with Sketch.</desc>
    <g fill="none">
      <g fill="#CC2C33">
        <path d="M19.2 10.6L29.2 10.6 29.2 35.6 0 35.6 0 10.6 10 10.6C10.3 10.6 10.6 10.9 10.6 11.3 10.6 11.6 10.3 11.9 10 11.9L1.3 11.9 1.3 34.2 27.9 34.2 27.9 11.9 19.2 11.9C18.9 11.9 18.6 11.6 18.6 11.3 18.6 10.9 18.9 10.6 19.2 10.6ZM8.4 18.8L13.9 24.3 13.9 0.7C13.9 0.3 14.2 0 14.6 0 15 0 15.3 0.3 15.3 0.7L15.3 24.2 20.8 18.8C21 18.5 21.4 18.5 21.7 18.8 21.9 19 21.9 19.4 21.7 19.7L15.1 26.3C15 26.4 14.8 26.5 14.6 26.5 14.6 26.5 14.6 26.5 14.6 26.5 14.6 26.5 14.6 26.5 14.6 26.5 14.4 26.5 14.2 26.5 14.1 26.3L7.5 19.7C7.2 19.5 7.2 19 7.5 18.8 7.7 18.5 8.2 18.5 8.4 18.8Z" />
      </g>
    </g>
  </svg>
)

export {
  Logo,
  PhoneIcon,
  MailIcon,
  PinIcon,
  LinkedInIcon,
  FacebookIcon,
  Chevron,
  SearchIcon,
  DownloadIcon,
}
