import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/fr'
import 'normalize.css'
import './index.scss'

import Header from './header'
import Footer from './footer'
import { Helmet } from 'components'

moment().locale('fr')

const Layout = ({ children, className, hidePhone, hideFooter, ...props }) => (
  <Fragment>
    <Helmet
      title='Nomade, gestion immobilière'
      description='Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire.'
    />
    <Header hidePhone={hidePhone} />
    <article className={className || ''}>{children}</article>
    {!hideFooter && <Footer />}
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
