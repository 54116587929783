import React, { Component } from 'react'
import { Link } from 'gatsby'

import { Logo } from 'components'

const menu = [
  {
    to: '',
    title: 'à LOUER',
  },
  {
    to: '/residentiel',
    title: 'residentiel',
    submenu: true,
  },
  {
    to: '/a-propos',
    title: 'NOTRE Entreprise',
  },
  // {
  //   to: '/equipe',
  //   title: 'notre Équipe',
  // },
  {
    to: '/contact',
    title: 'nous joindre',
  },
]

const MenuButton = ({ active, onClick }) => (
  <div className={`menu-button${active ? ' active' : ''}`} onClick={onClick}>
    <div />
    <div />
    <div />
  </div>
)

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className="phone-icon"
  >
    <desc>Created with Sketch.</desc>
    <g fill="none">
      <g fill="#CC2C33">
        <path d="M19.2 15.7L22.1 18.5C22.6 19 22.9 19.6 22.9 20.3 22.9 20.9 22.6 21.5 22.1 22.1 21.9 22.3 21.6 22.5 21.4 22.7 21.1 23 20.8 23.3 20.5 23.7 20.5 23.7 20.5 23.7 20.5 23.7 19.7 24.5 18.7 25 17.5 25 17.4 25 17.3 25 17.2 25 15.4 24.8 13.9 24.2 12.7 23.6 9.6 22.1 6.9 20 4.6 17.3 2.8 15.1 1.5 13 0.7 10.7 0.3 9.8-0.1 8.4 0 6.9 0.1 6 0.5 5.2 1.1 4.5L2.9 2.8C2.9 2.8 2.9 2.8 2.9 2.8 3.4 2.3 4 2 4.6 2 5.3 2 5.9 2.3 6.4 2.8 6.7 3.1 7 3.4 7.3 3.7 7.5 3.9 7.7 4 7.8 4.2L9.2 5.6C10.3 6.7 10.3 8.1 9.2 9.2 9.1 9.3 8.9 9.5 8.8 9.6 8.4 10 8 10.4 7.6 10.8 7.9 11.5 8.3 12.2 9 13 10.3 14.6 11.7 15.9 13.2 16.8 13.4 16.9 13.5 17 13.7 17.1 13.9 17.2 14 17.2 14.1 17.3L15.7 15.7C16.3 15.2 16.9 14.9 17.5 14.9 18.1 14.9 18.7 15.2 19.2 15.7ZM21.1 21.1C21.6 20.6 21.6 20 21.1 19.5L18.2 16.7C18.2 16.7 18.2 16.7 18.2 16.7 18.1 16.5 17.8 16.3 17.5 16.3 17.2 16.3 16.9 16.5 16.7 16.7L15 18.4C14.9 18.5 14.6 18.8 14.2 18.8 14 18.8 13.8 18.7 13.7 18.6 13.6 18.6 13.6 18.6 13.6 18.6 13.5 18.5 13.3 18.4 13.1 18.3 12.9 18.2 12.7 18.1 12.5 18 10.8 16.9 9.3 15.6 7.9 13.8L7.9 13.8C7.1 12.8 6.6 12 6.2 11.1 6.2 11.1 6.2 11.1 6.2 11.1 6.1 10.8 6 10.3 6.5 9.9 6.5 9.9 6.5 9.9 6.5 9.9 7 9.5 7.4 9.1 7.8 8.6 7.9 8.5 8.1 8.3 8.2 8.2 8.8 7.6 8.8 7.1 8.2 6.6L6.8 5.2C6.7 5 6.5 4.9 6.3 4.7 6 4.4 5.7 4.1 5.4 3.8 5.4 3.8 5.4 3.8 5.4 3.7 5.2 3.6 5 3.4 4.7 3.4 4.4 3.4 4.1 3.5 3.9 3.8L2.1 5.5C1.7 5.9 1.5 6.4 1.4 7 1.3 8 1.5 9 2 10.3 2.8 12.4 3.9 14.3 5.7 16.4 7.8 19 10.4 21 13.3 22.4 14.3 22.9 15.8 23.5 17.3 23.6 17.4 23.6 17.5 23.6 17.5 23.6 18.3 23.6 18.9 23.3 19.5 22.8 19.8 22.4 20.1 22.1 20.5 21.7 20.7 21.5 20.9 21.3 21.1 21.1 21.1 21.1 21.1 21.1 21.1 21.1Z" />
        <path d="M13.5 6.3C14.8 6.5 16 7.1 16.9 8.1 17.9 9 18.5 10.2 18.7 11.5 18.7 11.8 19 12 19.3 12 19.4 12 19.4 12 19.4 12 19.8 11.9 20.1 11.6 20 11.2 19.7 9.7 19 8.3 17.9 7.1 16.7 6 15.3 5.3 13.8 5 13.4 4.9 13.1 5.2 13 5.5 12.9 5.9 13.2 6.3 13.5 6.3" />
        <path d="M24.5 10.8C24.1 8.1 22.8 5.6 20.9 3.7 18.9 1.7 16.5 0.5 13.8 0 13.4-0.1 13.1 0.2 13 0.6 12.9 1 13.2 1.3 13.6 1.4 16 1.8 18.2 2.9 19.9 4.7 21.6 6.4 22.8 8.6 23.2 11 23.2 11.3 23.5 11.6 23.9 11.6 23.9 11.6 23.9 11.5 24 11.5 24.4 11.5 24.6 11.1 24.5 10.8" />
      </g>
    </g>
  </svg>
)

const Phone = () => (
  <a href="tel:+14185691599" className="nav-phone">
    <PhoneIcon />
    <span>418.569.1599</span>
  </a>
)

class Header extends Component {
  state = {
    open: false,
  }

  handleToggle = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    let { hidePhone } = this.props
    let { open } = this.state
    return (
      <div className={`header container ${hidePhone ? ' phone-hidden' : ''}`}>
        <div className="left-side">
          <MenuButton active={open} onClick={this.handleToggle} />
          <Link to="/">
            <Logo />
          </Link>
          <nav className={`nav-menu${open ? ' active' : ''}`}>
            <ul>
              {menu.map(({ to, title, submenu }) => (
                <li className={submenu ? 'submenu' : ''} key={title}>
                  {to !== '' ? (
                    <Link to={to}>{title}</Link>
                  ) : (
                    <div>{title}</div>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <Phone />
      </div>
    )
  }
}

export default Header
