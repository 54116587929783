import React from 'react'
import {
  Logo,
  PhoneIcon,
  MailIcon,
  PinIcon,
  LinkedInIcon,
  FacebookIcon,
} from 'components'

const Footer = () => (
  <footer className="background-grey">
    <div className="footer container">
      <Logo />
      <div className="footer-contacts">
        <div>
          <PhoneIcon />
          <a href="tel:+14185691599">(418) 569-1599</a>
        </div>
        <div>
          <MailIcon />
          <a href="mailto:sboivin@nomadegestion.com">
            sboivin@nomadegestion.com
          </a>
        </div>
        <div>
          <PinIcon />
          1255, boulevard Lebourgneuf, bur. 560, Québec (Qc) G2K 0M6
        </div>
        {/* <div className="social-icons">
          <a
            target="_blank"
            href="https://www.linkedin.com/company/impactgestion/"
            rel="no-follow noopener noreferrer"
            className="social-icon"
          >
            <LinkedInIcon />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/impactgestionimmobiliere/"
            rel="no-follow noopener noreferrer"
            className="social-icon"
          >
            <FacebookIcon />
          </a>
        </div> */}
      </div>
    </div>
  </footer>
)

export default Footer
