import React from 'react'
import { navigateTo } from 'gatsby'
import NetlifyForm from 'react-netlify-form'

const ContactForm = ({ name, title }) => (
  <NetlifyForm
    name={name || 'Contact Form'}
    onSuccess={() => navigateTo('/confirmation')}
  >
    {({ loading, error, success }) => (
      <div className="width-full">
        {loading && <div>En chargement...</div>}
        {error && (
          <div>
            Vos informations n'ont pas pu être envoyées. Réessayez encore.
          </div>
        )}
        {success && <div>Merci de nous avoir contacté!</div>}
        {!loading && !success && (
          <div className="flex space-between">
            <input
              type="text"
              name="title"
              defaultValue={title || '/contact'}
              hidden
            />
            <div className="left">
              <div className="field">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nom et Prénom"
                  required
                />
              </div>
              <div className="field">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Courriel"
                  required
                />
              </div>
              <div className="field">
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="Téléphone"
                  required
                />
              </div>
            </div>
            <div className="right">
              <div className="field">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  placeholder="Message"
                  required
                />
              </div>
              <div className="field">
                <button type="submit" className="special">
                  ENVOYEZ
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </NetlifyForm>
)

export default ContactForm
