import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const HelmetComponent = ({
  title,
  url,
  description,
  keywords,
  image,
}) => (
  <Helmet title={title}>
    <html lang="fr" />
    <meta charset="UTF-8" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />

    <meta property="og:url" content={`https://impactgestion.com${url}`} />
    <meta property="og:type" content="Impact, gestion immobilière" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
  </Helmet>
)

HelmetComponent.defaultProps = {
  title: 'Nomade, gestion immobilière',
  description: 'Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire.',
  url: '',
  image: 'https://impactgestion.com/facebook_og.png',
  keywords: ''
}

export default HelmetComponent
