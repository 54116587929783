import React, { Component } from 'react'
import Img from 'gatsby-image'
import Slider from 'react-slick'

class SliderComponent extends Component {
	state = {
		active: false,
		initialSlide: 0,
		height: 0
	}

	componentDidMount () {
		if (document) {
			const height = document.getElementById('modal-w').clientHeight - 200
			this.setState({ height })
		}
	}

	handleToggle = initialSlide => {
		this.slider.slickGoTo(initialSlide, true)
		this.setState(prev => ({
			initialSlide: typeof initialSlide === 'number' ? initialSlide : prev.initialSlide,
			active: !prev.active
		}))
	}

	render () {
		var settings = {
			dots: false,
			arrows: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			centerMode: true,
			centerPadding: 0
			// variableWidth: true
			// initialSlide: this.state.initialSlide
		}
		let { images, plans, imagesModal } = this.props
		// console.log("images:", images);
		let { active } = this.state

		return (
			<div>
				{plans ? (
					<div className='plans'>
						{imagesModal &&
							imagesModal.map(({ id, fluid }, i) => (
								<div className='plan open-slider-image' onClick={() => this.handleToggle(i)} key={id}>
									<Img fluid={fluid} className='image-in-slider' />
								</div>
							))}
					</div>
				) : (
					imagesModal && (
						<div className='open-slider-image' onClick={this.handleToggle}>
							<Img fluid={imagesModal[0].fluid} />
						</div>
					)
				)}

				<div className={`modal ${active ? 'active' : ''}`} id='modal-w'>
					<div className='close-button' onClick={this.handleToggle} />
					<Slider {...settings} ref={slider => (this.slider = slider)}>
						{imagesModal &&
							imagesModal.map(({ id, fluid }) => {
								// console.log("fluid: ", fluid);
								return (
									<div className='image-in-slider-container' key={id}>
										<Img
											fluid={fluid}
											className='image-in-slider'
											imgStyle={{ objectFit: 'contain', height: this.state.height }}
											style={{ height: this.state.height }}
										/>
									</div>
								)
							})}
					</Slider>
				</div>
			</div>
		)
	}
}

export default SliderComponent
